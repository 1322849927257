import { Header } from "./Header";

export const App: React.FC<{}> = () => {
  return (
    <>
      <Header />
      <div className="h-screen bg-gradient-to-b from-black to-cyan-900 flex flex-row justify-center items-center">
        <div className="text-5xl font-bold">
          <span>rundquist</span>
          <span className="text-cyan-300">.tech</span>
        </div>
      </div>
    </>
  );
};
