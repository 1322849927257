import * as React from "react";

export const Header: React.FC<{}> = () => {
  return (
    <header>
      <div>
        <a href="/">
          rundquist<span className="text-cyan-300">.tech</span>
        </a>
      </div>
      <nav>
        <a href="/privacy.html">Privacy</a>
        <a href="/spinit">SpinIt</a>
      </nav>
    </header>
  );
};
